import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { CONFIG } from "../../config/constant";

const SubscriptionSuccessPage = (props) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    updateTransactionStatus();
  }, []);

  async function updateTransactionStatus() {
    const url = CONFIG.BASE_URL + CONFIG.PURCHASE_CONFIRM;
    const transaction_id = searchParams.get("transaction_id");
    let token = props.auth.token;
    let data = { transaction_id };
    await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
  }

  return (
    <div className="subscription-success-page">
      <div className="message-wrapper">
        Congratulations, you have Subscribed Successfully !
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SubscriptionSuccessPage
);
