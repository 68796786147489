import React, { useState } from 'react';
import { Input, FormGroup } from 'reactstrap';
import { CONFIG } from '../../config/constant';

export const FileUpload = (props) => {
    const [fileName, setFileName] = useState('');
    const [invalidFile, setInvalidFile] = useState(false);
    const { custom } = props;
    const fileTypes =['jpg', 'jpeg', 'png', 'mp3', 'wav', 'aac', 'wma', 'mp4', 'mpeg-4', 'mov', 'avi'];

    const handleFileChange = ({ target: { files } }) => {
        const cancel = !files.length;
        if (cancel) return;
        const [{ name }] = files;
        setFileName(name);
        if (props.handleFileChange) {
            props.handleFileChange(files[0], custom);
        }
    }

    return (
        <FormGroup>
            <Input
                type="file"
                id="FileBrowser"
                name="FileBrowser"
                label={fileName || 'Select Folder'}
                onChange={handleFileChange}
                invalid={invalidFile} />
        </FormGroup>
    );
}

export const getFileUrl = (filename, token) => {
    return new Promise(async (resolve, reject) => {
        const url = CONFIG.BASE_URL + 'content/upload-url';
        let data = {
            filename
        };
        let response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(data)
        });
        if (response.status === 200) {
            let fileUrl = response.text();
            resolve(fileUrl);
        } else {
            resolve('');
        }
    });
}
