/* eslint-disable import/no-anonymous-default-export */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth/auth.reducer';
import profile from './profile/profile.reducer';
import content from './content/content.reducer';
import device from './device/device.reducer';

export default function(history) {
  return combineReducers({
    auth,
    profile,
    content,
    device,
    router: connectRouter(history)
  });
}
