import React, {useState} from "react";
import { useNavigate } from 'react-router-dom';

import './index.scss'


const Menu = (props) =>{
   const [active,setActive]=useState('');
   const navigate = useNavigate();
   function handleClick(event, type){
      event.preventDefault();
      event.stopPropagation();
      setActive(type);
      navigate(`/${type}`);
   }

    return(
        <div className="menu-container">
           <ul>
             <li className={active==='folder'?'folder active':'folder'} onClick={(e)=>handleClick(e,'folder')}><span></span>Content</li>
             <li className={active==='device-linking'?'device-linking active':'device-linking'} onClick={(e)=>handleClick(e,'device-linking')}><span></span>Device Linking</li>
             {props.role!=='subuser'?<li className={active==='packages'?'packages active':'packages'} onClick={(e)=>handleClick(e,'packages')}><span></span>Packages</li>:null}
             <li className={active==='schedule'?'schedule active':'schedule'} onClick={(e)=>handleClick(e,'schedule')}><span></span>Schedules</li>
             <li className={active==='account'?'account active':'account'} onClick={(e)=>handleClick(e,'account')}><span></span>Account</li>
           </ul>
        </div>
    )

};

export default Menu;