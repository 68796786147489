import { createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import logger from "redux-logger";
import createRootReducer from "./root-reducer";

function reduxMulti({ dispatch }) {
  return (next) => (action) => {
    return Array.isArray(action)
      ? action.filter(Boolean).map(dispatch)
      : next(action);
  };
}

const storeFunc = (history, initialState = {}) => {
  const middlewares = [thunk, routerMiddleware(history), reduxMulti];
  return createStore(
    createRootReducer(history),
    initialState,
    applyMiddleware(...middlewares)
  );
};

export default storeFunc;
