import React, { useState, Fragment, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import "./index.scss";
import { CONFIG } from "../../config/constant";
import PackCard from "../../component/PackCard";
import Footer from "../../component/Footer/Footer";

const PackagesPage = (props) => {
  const [packageList, setPackageList] = useState([]);

  useEffect(() => {
    getPackageList();
  }, []);

  async function getPackageList() {
    const url = CONFIG.BASE_URL + "subscription/plans";
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    response = await response.json();
    if (response && response.length > 0) {
      setPackageList(response);
    }
  }

  async function subscribePack(e, data) {
    let payload = {
      planID: data._id,
      amount: data.price,
    };
    const url = CONFIG.BASE_URL + "subscription/purchase";
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(payload),
    });
    response = await response.json();
    if (response.message) {
      window.location.href = response.message;
    }
  }

  return (
    <Fragment>
      <div className="subscription-page">
        <div className="subscription-header">
          <div className="page-name">Subscription</div>
        </div>
        <div className="card-wrapper">
          {packageList && packageList.length > 0
            ? packageList.map((item, i) => {
                return (
                  <PackCard key={i} data={item} clickHandler={subscribePack} />
                );
              })
            : null}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

// export default Splash;
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PackagesPage
);
