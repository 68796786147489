import React from "react";
import { useNavigate } from "react-router-dom";

import "./index.scss";

import facebookLogo from "../../assets/images/facebook.svg";
import twitterLogo from "../../assets/images/twitter.svg";
import instaLogo from "../../assets/images/instagram.svg";
import linkedinLogo from "../../assets/images/linkedin.svg";

const Footer = (props) => {
  const navigate = useNavigate();
  const footerList = [
    { key: "copyright", label: "Copyright Disclaimer" },
    { key: "termsofuse", label: "Terms Of Use" },
    { key: "privacypolicy", label: "Privacy Policy" },
    // {key:'helpcenter',label:'Help Center'},
    { key: "faq", label: "FAQ's" },
    { key: "howto", label: "How To's" },
    { key: "contact", label: "Contact" },
  ];

  function clickHandling(e, key) {
    navigate(`/${key}`);
  }

  return (
    <div className="footer-container" style={props.wrapperStyle}>
      <div className="social-logos">
        <img className="facebook" src={facebookLogo} />
        <img className="twitter" src={twitterLogo} />
        <img className="instagram" src={instaLogo} />
        <img className="linkedin" src={linkedinLogo} />
      </div>
      <ul>
        {footerList.map((item) => {
          return (
            <li
              onClick={(e) => clickHandling(e, item.key)}
              key={item.key}
              style={props.childStyle}
            >
              {item.label}
            </li>
          );
        })}
      </ul>
      <div className="copyright" style={props.childStyle}>
        ©2023 by TVYou.com. All Rights Reserved
      </div>
    </div>
  );
};

export default Footer;
