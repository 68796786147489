import React, { useEffect, useState, Fragment } from "react";
import Footer from "../../component/Footer/Footer";
import "./index.scss";
import { CONFIG } from "../../config/constant";

const Terms = (props) => {
  const [staticData, setStaticData] = useState(null);
  useEffect(() => {
    getStaticContents();
  }, []);

  const getStaticContents = async () => {
    const url = `${CONFIG.BASE_URL}static_content/`;
    let response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      response = await response.json();
      formatData(response);
    }
  };

  function formatData(response) {
    for (let i in response) {
      if (response[i].key === "terms") {
        setStaticData(response[i]);
        break;
      }
    }
  }

  return (
    <Fragment>
      <div className="static-page">
        <div className="static-header">
          <h1
            className="label"
            dangerouslySetInnerHTML={{ __html: staticData?.label }}
          ></h1>
        </div>
        <div
          className="static-content"
          dangerouslySetInnerHTML={{ __html: staticData?.data }}
        ></div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Terms;
