import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import "./index.scss";
import logo from "../../assets/images/logo_new.jpg";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";
import { setAuthInfo } from "../../module/auth/auth.action";
import { CONFIG } from "../../config/constant";
import Footer from "../../component/Footer/Footer";
import appleStore from "../../assets/images/apple-store.jpg";
import googleStore from "../../assets/images/google-playstore.jpg";

const LoginPage = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [commonError, setCommonError] = useState("");
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const cookies = new Cookies();

  const navigate = useNavigate();
  function goToCreateAccount() {
    navigate("/signup");
  }
  function goToForgetAccount() {
    navigate("/forget");
  }

  function isValidEmail() {
    if (!email) {
      setCommonError("Email required");
      return false;
    }
    if (!email.match(regex)) {
      setCommonError("Invalid Email");
      return false;
    }
    return true;
  }

  function isValidPassword() {
    if (!password) {
      setCommonError("Password required");
      return false;
    }
    return true;
  }

  async function Login() {
    if (!isValidEmail()) {
      return;
    }
    if (!isValidPassword()) {
      return;
    }
    if (email && password) {
      const data = { email: email.toLowerCase(), password };
      const url = CONFIG.BASE_URL + "auth/login";
      console.log(data, url);
      let response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response && response.status === 200) {
        let data = await response.json();
        props.setAuthInfo({
          email: data.email,
          token: data.token,
          role: data.role,
          userid: data._id,
          device_ids: data.device_ids || [],
          owner_id: data.owner_id,
          isLogin: true,
        });
        cookies.set("email", data.email, { path: "/" });
        cookies.set("token", data.token, { path: "/" });
        cookies.set("role", data.role, { path: "/" });
        cookies.set("userid", data._id, { path: "/" });
        cookies.set("device_ids", data.device_ids, { path: "/" });
        cookies.set("owner_id", data.owner_id, { path: "/" });
        cookies.set("isLogin", true, { path: "/" });
        setTimeout(() => {
          navigate("/folder");
        }, 500);
      } else {
        response = await response.json();
        toast(response.message || response.error_message);
        setCommonError(response.message || response.error_message);
        // setTimeout(() => {
        //   setCommonError("");
        // }, 3000);
      }
    }
  }

  return (
    <Fragment>
      <div className="login-page">
        <div className="header">
          <img className="app-logo" src={logo} alt="" />
          <div className="store-logo">
            <div className="coming-soon">Coming Soon</div>
            <div className="logos">
              <img className="store-image" src={appleStore} alt="appleStore" />
              <img
                className="store-image"
                src={googleStore}
                alt="googleStore"
              />
            </div>
          </div>
        </div>

        <div className="content">
          <div className="left-section"></div>
          <div className="right-section">
            <h2 className="title"> Sign in</h2>
            <form
              onSubmit={() => {
                return;
              }}
            >
              <input
                type="text"
                placeholder="Email *"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setCommonError("");
                }}
              />
              <input
                type="password"
                placeholder="Password *"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setCommonError("");
                }}
              />
              <div className="common-error">{commonError}</div>
              <input type="button" onClick={Login} value="Sign In" />
              <div className="forget" onClick={goToForgetAccount}>
                Forgot password?
              </div>
              <div className="create-account">
                New to TVYou?{" "}
                <span onClick={goToCreateAccount}>Sign up now.</span>
              </div>{" "}
            </form>
          </div>
        </div>

        <Footer />
      </div>
      <ToastContainer />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setAuthInfo,
    },
    dispatch
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(LoginPage);
