import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import LoginPage from "./page/Login";
import SignupPage from "./page/Signup";
import ForgetPage from "./page/Forget";
import FolderPage from "./page/Folders";
import ContentPage from "./page/Content";
import DevicesPage from "./page/Devices";
import PackagesPage from "./page/Packages";
import SubscriptionSuccessPage from "./page/SubscriptionSuccess";
import AccountPage from "./page/Account";
import PrivacyPage from "./page/Privacy";
import TermsPage from "./page/Terms";
import FaqPage from "./page/Faq";
import HowtosPage from "./page/Howtos";
import ContactPage from "./page/Contact";
import CopyrightPage from "./page/Copyright";
import SchedulePage from "./page/Schedule";
import SetPassword from "./page/SetPassword";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Menu from "./component/Menu";
import "./App.css";

function App(props) {
  let { isLogin, role } = props.auth;
  isLogin = isLogin === "true" || isLogin;
  return (
    <BrowserRouter>
      <div className="App">
        {isLogin ? <Header /> : null}
        {isLogin ? <Menu role={role} /> : null}
        <Routes>
          <Route
            exact
            path="/login"
            element={isLogin ? <Navigate to="/folder" /> : <LoginPage />}
          />
          <Route
            exact
            path="/signup"
            element={isLogin ? <Navigate to="/folder" /> : <SignupPage />}
          />
          <Route
            exact
            path="/forget"
            element={isLogin ? <Navigate to="/folder" /> : <ForgetPage />}
          />
          <Route
            exact
            path="/content/:id/:title"
            element={isLogin ? <ContentPage /> : <Navigate to="/login" />}
          />
          <Route
            exact
            path="/folder"
            element={isLogin ? <FolderPage /> : <Navigate to="/login" />}
          />
          <Route
            exact
            path="/packages"
            element={isLogin ? <PackagesPage /> : <Navigate to="/login" />}
          />
          <Route
            exact
            path="/device-linking"
            element={isLogin ? <DevicesPage /> : <Navigate to="/login" />}
          />
          <Route
            exact
            path="/subscription/success"
            element={
              isLogin ? <SubscriptionSuccessPage /> : <Navigate to="/login" />
            }
          />
          <Route
            exact
            path="/account"
            element={isLogin ? <AccountPage /> : <Navigate to="/login" />}
          />
          <Route
            exact
            path="/schedule"
            element={isLogin ? <SchedulePage /> : <Navigate to="/login" />}
          />
          <Route exact path="/set-password/:token" element={<SetPassword />} />
          <Route
            exact
            path="/copyright"
            element={<CopyrightPage isLogin={isLogin} />}
          />
          <Route
            exact
            path="/termsofuse"
            element={<TermsPage isLogin={isLogin} />}
          />
          <Route
            exact
            path="/privacypolicy"
            element={<PrivacyPage isLogin={isLogin} />}
          />
          <Route exact path="/faq" element={<FaqPage isLogin={isLogin} />} />
          <Route
            exact
            path="/howto"
            element={<HowtosPage isLogin={isLogin} />}
          />
          <Route
            exact
            path="/contact"
            element={<ContactPage isLogin={isLogin} />}
          />
          <Route
            path="/"
            element={isLogin ? <FolderPage /> : <Navigate to="/login" />}
          >
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
