import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./index.scss";
import logo from "../../assets/images/logo_new.jpg";
import { CONFIG } from "../../config/constant";

const SetPassword = (props) => {
  const params = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [commonError, setCommonError] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setToken(params.token);
  }, []);

  function goToLogin() {
    navigate("/login");
  }

  function isValidPassword() {
    if (!password) {
      setPasswordError("Password required");
      return false;
    }
    return true;
  }

  function isValidConfirmPassword() {
    if (!confirmPassword) {
      setConfirmPasswordError("ConfirmPassword required");
      return false;
    }
    return true;
  }

  async function changePassword() {
    if (!isValidPassword()) {
      return;
    }

    if (!isValidConfirmPassword()) {
      return;
    }

    if (password !== confirmPassword) {
      setCommonError("Password and Confirm Password doesn't match");
      return;
    }

    const data = { password, token };
    const url = CONFIG.BASE_URL + "auth/set-password";
    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    response = await response.json();
    if (response?.error_message) {
      setCommonError(response.error_message);
    } else {
      setPassword("");
      setConfirmPassword("");
      toast(response?.message);
      setTimeout(() => {
        goToLogin();
      }, 2000);
    }
  }

  return (
    <div className="forget-page">
      <img src={logo} alt="" />
      <div className="forget-container">
        <div className="left-container">
          <h2>
            Neque porro quisquam est qui dolorem ipsum quia dolor sit amet
          </h2>
          <div className="description">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </div>
        </div>
        <div className="right-container-otp">
          <Fragment>
            <div className="input-wrapper">
              <label>Password</label>
              <input
                type="text"
                placeholder="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError("");
                }}
              />
              <span className="error">{passwordError}</span>
            </div>
            <div className="input-wrapper">
              <label>Confirm Password</label>
              <input
                type="text"
                placeholder="password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setConfirmPasswordError("");
                }}
              />
              <span className="error">{confirmPasswordError}</span>
            </div>
            <div className="common-error">{commonError}</div>
            <button onClick={changePassword}>Set Password</button>
          </Fragment>
          <div className="forget" onClick={goToLogin}>
            Back to login
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SetPassword;
