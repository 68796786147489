import React, { useState, useEffect, Fragment } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Label, FormGroup } from "reactstrap";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import DateTimePicker from "react-datetime-picker";
import { FileUpload, getFileUrl } from "../../component/FileUpload";

import "./index.scss";
import { CONFIG } from "../../config/constant";
import {
  Popup,
  PopupHeader,
  PopupFooter,
  PopupContent,
} from "../../component/Popup/Popup";
import { setDevices } from "../../module/device/device.action";
import deviceIcon from "../../assets/images/devices.svg";
import editIcon from "../../assets/images/edit-black.svg";
import deleteIcon from "../../assets/images/delete1.svg";

import { default as ReactSelect, components } from "react-select";
import Footer from "../../component/Footer/Footer";

const Schedule = (props) => {
  const [scheduleList, setScheduleList] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [startDate, onChangeStart] = useState(new Date());
  const [endDate, onChangeEnd] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [addDeviceModal, setAddDeviceModal] = useState(false);

  const [optionSelected, setOptionSelected] = useState("");
  const [optionData, setOptionData] = useState([]);

  useEffect(() => {
    getScheduleList();
    if (props.device.devices && !props.device.devices.length) {
      getDevices();
    } else {
      formatDeviceData();
    }
  }, []);

  async function getScheduleList() {
    const url = CONFIG.BASE_URL + `scheduled_content`;
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      setIsLoading(false);
      response = await response.json();
      setScheduleList(response);
    }
  }

  async function getDevices() {
    const url = CONFIG.BASE_URL + CONFIG.DEVICE_LIST;
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      response = await response.json();
      props.setDevices(response.data);
      setIsLoading(false);
      formatDeviceData(response.data);
    }
  }

  async function deleteSchedule() {
    const url = CONFIG.BASE_URL + `scheduled_content/${selectedSchedule._id}`;
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      response = await response.json();
      toast(response.message);
      getScheduleList();
    }
  }

  async function updateSchedule() {
    const url = CONFIG.BASE_URL + `scheduled_content/${selectedSchedule._id}`;
    let token = props.auth.token;
    let obj = {};
    obj.device_ids = [];
    if (optionSelected && optionSelected.length > 0) {
      obj.device_ids = optionSelected.map((item) => item.value);
    }
    obj.status = selectedSchedule.status;
    obj.start_date = selectedSchedule.start_date;
    obj.end_date = selectedSchedule.end_date;
    obj.title = selectedSchedule.title;
    obj.description = selectedSchedule.description;

    let response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    });
    if (response.status === 200) {
      response = await response.json();
      toast(response.message);
      getScheduleList();
    }
  }

  function toggleDeleteSchedule(flag) {
    if (flag) {
      deleteSchedule();
    }
    setIsDelete(false);
  }

  function toggleSchedulePopup(flag) {
    if (flag) {
      updateSchedule();
    }
    setIsEdit(false);
  }

  async function handleFileChange(file, key) {
    let fileUrl = await getFileUrl(file.name, props.auth.token);
    let fileReader = new FileReader();
    fileReader.onload = function (e) {
      let blob = new Blob([new Uint8Array(e.target.result)], {
        type: file.type,
      });
      onLoadsuccess(blob, file.type, fileUrl, key);
    };
    fileReader.readAsArrayBuffer(file);
  }

  async function onLoadsuccess(blob, type, fileUrl, key) {
    let response = await fetch(fileUrl, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": type,
      },
      body: blob,
    });
    if (response.status === 200) {
      let finalUrl = fileUrl?.split("?")[0];
      setSelectedSchedule({ ...selectedSchedule, thumbnail: finalUrl });
    }
  }

  function getFormatedDate(dateString) {
    var date = new Date(dateString);
    var time = date.toLocaleString("en-US", {
      date: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    var month = date.getMonth();
    month = month < 10 ? `0${month}` : month;
    var day = date.getDate();
    day = day < 10 ? `0${day}` : day;
    return `${date.getFullYear()}-${month}-${day}  ${time}`;
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleChange = (selected) => {
    setOptionSelected(selected);
  };

  function formatDeviceData(data) {
    const deviceList = [];
    let devices = data || props.device.devices;
    if (devices.length > 0) {
      for (let i = 0; i < devices.length; i++) {
        let obj = {
          value: devices[i].device_id,
          label: devices[i].device_name,
        };
        deviceList.push(obj);
      }
      setOptionData(deviceList);
    }
  }

  function filterSelectedScheduleDevice(item) {
    // onChangeStart(item.start_date);
    // onChangeEnd(item.end_date);
    const deviceList = [];
    if (props.device.devices.length > 0) {
      for (let i = 0; i < props.device.devices.length; i++) {
        if (
          item &&
          item.device_ids.includes(props.device.devices[i].device_id)
        ) {
          let obj = {
            value: props.device.devices[i].device_id,
            label: props.device.devices[i].device_name,
          };
          deviceList.push(obj);
        }
      }
      setOptionSelected(deviceList);
      setSelectedSchedule(item);
      // setIsEdit(true);
    }
  }

  const showDeviceList = (item) => {
    if (document.querySelector(`.collapse-pan.active`)) {
      document.querySelector(`.collapse-pan.active`).classList.remove("active");
    }
    if (
      document
        .querySelector(`#collapsepan-${item._id}`)
        .classList.contains("active")
    ) {
      document
        .querySelector(`#collapsepan-${item._id}`)
        .classList.remove("active");
    } else {
      document
        .querySelector(`#collapsepan-${item._id}`)
        .classList.add("active");
    }
    if (
      document
        .querySelector(`#devicetab-${item._id}`)
        .classList.contains("active")
    ) {
      document
        .querySelector(`#devicetab-${item._id}`)
        .classList.remove("active");
    } else {
      document.querySelector(`#devicetab-${item._id}`).classList.add("active");
    }
    if (
      document
        .querySelector(`#listdevice-${item._id}`)
        .classList.contains("active")
    ) {
      document
        .querySelector(`#listdevice-${item._id}`)
        .classList.remove("active");
    } else {
      document.querySelector(`#listdevice-${item._id}`).classList.add("active");
    }
  };

  const openTabs = (e, id) => {
    // if(document.querySelector(`#listdevice-${id}`).classList.contains('active')){
    //   document.querySelector(`#listdevice-${id}`).classList.remove('active');
    // }else{
    document.querySelector(`#listdevice-${id}`).classList.add("active");
    // }
  };

  const linkDevice = (e, item) => {
    filterSelectedScheduleDevice(item);
    setAddDeviceModal(true);
  };

  async function deleteDevice(e, device, item) {
    const url = CONFIG.BASE_URL + `scheduled_content/${item._id}`;
    let token = props.auth.token;
    let obj = {};
    obj.device_ids = [];
    if (item && item.device_ids.length > 0) {
      obj.device_ids = item.device_ids.filter(
        (deviceid) => deviceid.device_id !== device.device_id
      );
      obj.device_ids = obj.device_ids.map((deviceid) => deviceid.device_id);
    }
    obj.title = item.title;
    obj.description = item.description;
    obj.start_date = item.start_date;
    obj.end_date = item.end_date;
    obj.content_id = item.content_id;
    obj.user_id = item.user_id;
    obj.status = item.status;
    let response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    });
    if (response.status === 200) {
      response = await response.json();
      toast(response.message);
      getScheduleList();
    }
  }

  function toggleAddDevicePopup(flag) {
    if (flag) {
      updateSchedule();
    }
    setAddDeviceModal(false);
  }

  return (
    <Fragment>
      <div className="schedule-page">
        <div className="page-header">
          <div className="page-name">Schedule List</div>
        </div>
        {scheduleList && scheduleList.length > 0 ? (
          <div className="schedule-list-container">
            <div className="list-header">
              <div className="header">Title</div>
              <div className="header">Description</div>
              <div className="header">Start Date</div>
              <div className="header">End Date</div>
              {props.auth.role !== "subuser" ? (
                <div className="header">Devices</div>
              ) : null}
              {props.auth.role !== "subuser" ? (
                <Fragment>
                  <div className="header">Edit</div>
                  <div className="header">Delete</div>
                </Fragment>
              ) : null}
            </div>
            {scheduleList && scheduleList.length > 0
              ? scheduleList.map((item) => {
                  return (
                    <div className="list-item" key={item._id}>
                      <div className="list-data">
                        <div className="schedule-details">{item.title}</div>
                        <div className="schedule-details">
                          {item.description}
                        </div>
                        <div className="schedule-details">
                          {getFormatedDate(item.start_date)}
                        </div>
                        <div className="schedule-details">
                          {getFormatedDate(item.end_date)}
                        </div>
                        {props.auth.role !== "subuser" ? (
                          <div className="schedule-details">
                            <img
                              className="devices-icon"
                              src={deviceIcon}
                              onClick={() => {
                                showDeviceList(item);
                              }}
                              alt="devices-icon"
                            />
                          </div>
                        ) : null}
                        {props.auth.role !== "subuser" ? (
                          <div className="schedule-details">
                            <img
                              className="edit-icon"
                              src={editIcon}
                              onClick={() => {
                                filterSelectedScheduleDevice(item);
                                setIsEdit(true);
                              }}
                              alt="edit-icon"
                            />
                          </div>
                        ) : null}
                        {props.auth.role !== "subuser" ? (
                          <div className="schedule-details">
                            <img
                              className="delete-icon"
                              src={deleteIcon}
                              onClick={() => {
                                setSelectedSchedule(item);
                                setIsDelete(true);
                              }}
                              alt="delete-icon"
                            />
                          </div>
                        ) : null}
                      </div>

                      <div
                        className="collapse-pan"
                        id={`collapsepan-${item._id}`}
                      >
                        <div className="tab">
                          <button
                            className="tablinks"
                            id={`devicetab-${item._id}`}
                            onClick={(e) => openTabs(e, item._id)}
                          >
                            Device List
                          </button>
                          {props.auth.role !== "subuser" ? (
                            <button
                              className="tablinks"
                              id={`addnewdevice-${item._id}`}
                              onClick={(e) => linkDevice(e, item)}
                            >
                              Add Device
                            </button>
                          ) : null}
                        </div>
                        <div
                          id={`listdevice-${item._id}`}
                          className="tabcontent"
                        >
                          {item.device_ids && item.device_ids.length > 0 ? (
                            <table>
                              <thead>
                                <tr>
                                  <th className="small">Device ID</th>
                                  <th className="small">Device Name</th>
                                  {props.auth.role !== "subuser" ? (
                                    <th className="small">Delete</th>
                                  ) : null}
                                </tr>
                              </thead>
                              <tbody>
                                {item.device_ids.map((device) => {
                                  return (
                                    <tr key={device.device_id}>
                                      <td className="large">
                                        {device.device_id}
                                      </td>
                                      <td className="large">
                                        {device.device_name}
                                      </td>
                                      {props.auth.role !== "subuser" ? (
                                        <td className="large">
                                          <img
                                            src={deleteIcon}
                                            alt="delete"
                                            onClick={(e) =>
                                              deleteDevice(e, device, item)
                                            }
                                          />
                                        </td>
                                      ) : null}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <div>No data available</div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        ) : null}
        {!isLoading && scheduleList && !scheduleList.length ? (
          <div className="no-device">No scheduled event available.</div>
        ) : null}
      </div>

      <Popup isOpen={isEdit}>
        <PopupHeader toggle={setIsEdit}>Edit Schedule</PopupHeader>
        <PopupContent>
          <input
            type="text"
            className="text-input"
            placeholder="Enter Title"
            onChange={(e) =>
              setSelectedSchedule({
                ...selectedSchedule,
                title: e.target.value,
              })
            }
            value={selectedSchedule?.title}
          />
          <input
            type="text"
            className="text-input"
            placeholder="Enter Description"
            onChange={(e) =>
              setSelectedSchedule({
                ...selectedSchedule,
                description: e.target.value,
              })
            }
            value={selectedSchedule?.description}
          />
          <select
            value={selectedSchedule?.status}
            onChange={(e) =>
              setSelectedSchedule({
                ...selectedSchedule,
                status: e.target.value,
              })
            }
          >
            <option key={"blank"} value={""}>
              Select Mode
            </option>
            <option key={"active"} value={"active"}>
              Active
            </option>
            <option key={"inactive"} value={"inactive"}>
              Inactive
            </option>
          </select>
          {/* <span style={{ textAlign: "left" }}>Update Thumbnail</span>
          <FileUpload handleFileChange={handleFileChange} custom="thumbnail" /> */}
          <FormGroup>
            <Label
              for="scheduleDate"
              style={{ width: "100%", textAlign: "left" }}
            >
              {" "}
              Schedule Start
            </Label>
            <DateTimePicker
              onChange={onChangeStart}
              value={startDate}
              minDate={new Date()}
              required={true}
              format="dd-MM-y h:mm:ss a"
              style={{ marginLeft: "-140px" }}
            />
          </FormGroup>
          <FormGroup>
            <Label
              for="scheduleDate"
              style={{ width: "100%", textAlign: "left" }}
            >
              {" "}
              Schedule End
            </Label>
            <DateTimePicker
              onChange={onChangeEnd}
              value={endDate}
              minDate={new Date()}
              required={true}
              format="dd-MM-y h:mm:ss a"
              style={{ marginLeft: "-140px" }}
            />
          </FormGroup>
          <FormGroup>
            <ReactSelect
              options={optionData}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
              onChange={handleChange}
              allowSelectAll={true}
              value={optionSelected}
              placeholder="Select Device"
            />
          </FormGroup>
        </PopupContent>
        <PopupFooter>
          <Button color="primary" onClick={() => toggleSchedulePopup(true)}>
            Okay
          </Button>
          <Button color="primary" onClick={() => toggleSchedulePopup(false)}>
            Cancel
          </Button>
        </PopupFooter>
      </Popup>

      <Popup isOpen={isDelete}>
        <PopupHeader toggle={(e) => setIsDelete(false)}>
          {" "}
          Delete Schedule
        </PopupHeader>
        <PopupContent class="small">
          <h6>Do you want to delete this schedule?</h6>
        </PopupContent>
        <PopupFooter>
          <Button color="primary" onClick={() => toggleDeleteSchedule(true)}>
            Okay
          </Button>
          <Button color="primary" onClick={() => toggleDeleteSchedule(false)}>
            Cancel
          </Button>
        </PopupFooter>
      </Popup>

      <Popup isOpen={addDeviceModal}>
        <PopupHeader toggle={toggleAddDevicePopup}>Add Device</PopupHeader>
        <PopupContent>
          <FormGroup>
            <ReactSelect
              options={optionData}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
              onChange={handleChange}
              allowSelectAll={true}
              value={optionSelected}
              placeholder="Select Device"
            />
          </FormGroup>
        </PopupContent>
        <PopupFooter>
          <Button color="primary" onClick={() => toggleAddDevicePopup(true)}>
            Okay
          </Button>
          <Button color="primary" onClick={() => toggleAddDevicePopup(false)}>
            Cancel
          </Button>
        </PopupFooter>
      </Popup>

      <ToastContainer />
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  device: state.device,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setDevices }, dispatch);
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Schedule);
