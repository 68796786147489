import React, { useState, Fragment, useEffect } from "react";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "./index.scss";
import {
  Popup,
  PopupHeader,
  PopupFooter,
  PopupContent,
} from "../../component/Popup/Popup";
// import { FileUpload, getFileUrl } from "../../component/FileUpload";
import { CONFIG } from "../../config/constant";
import { setFolder, setContent } from "../../module/content/content.action";
import { setDevices } from "../../module/device/device.action";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import Footer from "../../component/Footer/Footer";

import addIcon from "../../assets/images/add.svg";
import noFolderIcon from "../../assets/images/no-folder.png";
import folderIcon from "../../assets/images/folder.svg";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/delete.svg";

const Folders = (props) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [contentData, setContentData] = useState({
    content_url: "",
    title: "",
    // description: "",
    thumbnail: "",
    status: "",
    type: "directory",
    user_id: props.auth?.userid || "",
    parent_content_directory: "",
    is_directory: true,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props.content?.folders && !props.content?.folders?.length) {
      getFolder();
    }
    if (props.device.devices && !props.device.devices.length) {
      getDevices();
    }
    props.setContent([]);
  }, []);

  async function getFolder() {
    setIsLoading(true);
    const url = `${CONFIG.BASE_URL}content?is_directory=true`;
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      response = await response.json();
      setIsLoading(false);
      props.setFolder(response);
    } else {
      setIsLoading(false);
      props.setFolder([]);
    }
  }

  async function createFolder() {
    const url = CONFIG.BASE_URL + "content";
    let user_id = props.auth.userid;
    let token = props.auth.token;
    let data = {
      content_url: "",
      title: contentData.title,
      // description: contentData.description,
      thumbnail: "",
      // thumbnail: contentData.thumbnail,
      status: "active",
      type: contentData.type,
      user_id: contentData.user_id || user_id,
      parent_content_directory: "",
      is_directory: true,
    };
    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      toast("Folder Created Successfully.");
      await getFolder();
    } else {
      response = await response.json();
      toast(response.error_message);
    }
  }

  async function updateFolder() {
    const url = CONFIG.BASE_URL + `content/${selectedFolder._id}`;
    let token = props.auth.token;
    let data = {
      title: contentData.title,
      // description: contentData.description,
      thumbnail: "",
      status: "active",
      type: contentData.type,
    };
    let response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      toast("Folder Updated Successfully.");
      await getFolder();
    } else {
      response = await response.json();
      toast(response.error_message);
    }
  }

  async function deleteFolder() {
    const url = CONFIG.BASE_URL + `content/${selectedFolder._id}`;
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      toast("Folder Deleted Successfully.");
      await getFolder();
    } else {
      response = await response.json();
      toast(response.error_message);
    }
  }

  async function getDevices() {
    const url = CONFIG.BASE_URL + CONFIG.DEVICE_LIST;
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      response = await response.json();
      props.setDevices(response.data);
      setIsLoading(false);
    }
  }

  const toggle = async (flag) => {
    if (flag) {
      createFolder();
    }
    setModal(!modal);
  };

  const toggleEdit = async (flag) => {
    if (flag) {
      updateFolder();
    }
    setModal(!modal);
  };

  const openModel = async () => {
    setContentData({
      content_url: "",
      title: "",
      // description: "",
      thumbnail: "",
      status: "",
      type: "directory",
      user_id: props.auth?.userid || "",
      parent_content_directory: "",
      is_directory: true,
    });
    setIsDelete(false);
    setIsEdit(false);
    setModal(true);
  };

  function openFolder(e, item) {
    let name = e.target.getAttribute("name");
    switch (name) {
      case "edit":
        setIsEdit(true);
        setSelectedFolder(item);
        setContentData({
          content_url: item.content_url,
          title: item.title,
          // description: item.description,
          thumbnail: item.thumbnail,
          status: item.status,
          type: item.type,
          user_id: item.user_id,
          parent_content_directory: item.parent_content_directory,
          is_directory: item.is_directory,
        });
        setModal(true);
        break;
      case "delete":
        setSelectedFolder(item);
        setIsDelete(true);
        break;
      default:
        const { _id, title } = item;
        if (_id && title) {
          navigate(`/content/${_id}/${title}`);
        }
        break;
    }
  }

  const deleteDirectory = (flag) => {
    if (flag) {
      deleteFolder();
    }
    setIsDelete(false);
  };

  return (
    <Fragment>
      <div className="folder-page">
        <div className="page-header">
          <div className="page-name">Folders</div>
          {props.auth.role !== "subuser" ? (
            <span className="add" onClick={() => openModel("folder")}>
              Add Folder
            </span>
          ) : null}
        </div>
        {props.content.folders && props.content.folders.length ? (
          <div className="folder-wrapper">
            {props.content.folders.map((item) => {
              return (
                <div
                  className="folder"
                  key={item._id}
                  style={{ backgroundImage: `url(${folderIcon})` }}
                  onClick={(e) => openFolder(e, item)}
                >
                  {props.auth.role !== "subuser" ? (
                    <div className="icon-wrapper">
                      <img src={editIcon} alt="edit icon" name="edit" />
                      <img src={deleteIcon} alt="delete icon" name="delete" />
                    </div>
                  ) : null}
                  <div className="title">
                    <span>{item.title}</span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : !isLoading ? (
          <div className="no-folder">
            <div>Woops, no folder exist !</div>
            <img src={noFolderIcon} alt="no folder" />
          </div>
        ) : (
          <div className="no-folder">
            <div>Loading...</div>
          </div>
        )}
      </div>
      <Popup isOpen={modal}>
        <PopupHeader toggle={toggle}>
          {" "}
          {isEdit ? "Edit Folder" : "Add Folder"}
        </PopupHeader>
        <PopupContent>
          <input
            type="text"
            className="text-input"
            placeholder="Enter Folder Name"
            onChange={(e) =>
              setContentData({ ...contentData, title: e.target.value })
            }
            value={contentData.title}
          />
        </PopupContent>
        <PopupFooter>
          {isEdit ? (
            <Button color="primary" onClick={() => toggleEdit(true)}>
              Update
            </Button>
          ) : (
            <Button color="primary" onClick={() => toggle(true)}>
              Add
            </Button>
          )}
          <Button color="primary" onClick={() => toggle(false)}>
            Cancel
          </Button>
        </PopupFooter>
      </Popup>
      <Popup isOpen={isDelete}>
        <PopupHeader toggle={(e) => setIsDelete(false)}>
          {" "}
          Delete Folder
        </PopupHeader>
        <PopupContent class="small">
          <h6>Do you want to delete folder?</h6>
        </PopupContent>
        <PopupFooter>
          <Button color="primary" onClick={() => deleteDirectory(true)}>
            Okay
          </Button>
          <Button color="primary" onClick={() => deleteDirectory(false)}>
            Cancel
          </Button>
        </PopupFooter>
      </Popup>
      <ToastContainer />
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  content: state.content,
  device: state.device,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setFolder, setContent, setDevices }, dispatch);
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Folders);
