import React, { useState, Fragment, useEffect } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "reactstrap";
import "./index.scss";
import { CONFIG } from "../../config/constant";
import profileIcon from "../../assets/images/profile.png";
import {
  Popup,
  PopupHeader,
  PopupFooter,
  PopupContent,
} from "../../component/Popup/Popup";
import { ToastContainer, toast } from "react-toastify";
import { setDevices } from "../../module/device/device.action";
import { default as ReactSelect, components } from "react-select";
import deleteIcon from "../../assets/images/delete.png";
import Footer from "../../component/Footer/Footer";

const SubscriptionPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [profileDetails, setProfileDetails] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState();
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState("");
  const [optionSelected, setOptionSelected] = useState("");
  const [optionData, setOptionData] = useState([]);
  const [invitationList, setInvitationList] = useState(null);

  useEffect(() => {
    getAccountInfo();
    if (props.device.devices && !props.device.devices.length) {
      getDevices();
      getInvitationList();
    } else {
      formatData();
      getInvitationList();
    }
  }, []);

  function formatData() {
    const deviceList = [];
    if (props.device.devices.length > 0) {
      for (let i = 0; i < props.device.devices.length; i++) {
        let obj = {
          value: props.device.devices[i].device_id,
          label: props.device.devices[i].device_name,
        };
        deviceList.push(obj);
      }
      setOptionData(deviceList);
    }
  }
  useEffect(() => {
    if (
      profileDetails &&
      profileDetails.subscription &&
      profileDetails.subscription.length > 0
    ) {
      let date1 = new Date(profileDetails.subscription[0].createdAt);
      let date2 = new Date();
      let Difference_In_Time = date2.getTime() - date1.getTime();
      let Difference_In_Days = parseInt(
        Difference_In_Time / (1000 * 3600 * 24),
        10
      );
      date1.setDate(
        date1.getDate() +
          parseInt(profileDetails.subscription[0].plan_expiry, 10)
      );
      let EndDate = date1.toISOString();
      EndDate = EndDate.split("T")[0];
      setSubscriptionEndDate(EndDate);
      setNumberOfDays(Difference_In_Days);
    }
  }, [profileDetails]);

  async function getDevices() {
    const url = CONFIG.BASE_URL + CONFIG.DEVICE_LIST;
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      response = await response.json();
      props.setDevices(response.data);
      formatData();
    }
  }

  async function getAccountInfo() {
    const url = CONFIG.BASE_URL + `account?userId=${props.auth.userid}`;
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      setIsLoading(false);
      response = await response.json();
      setProfileDetails(response);
    }
  }

  async function getInvitationList() {
    const url = CONFIG.BASE_URL + `auth/invite`;
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      setIsLoading(false);
      response = await response.json();
      setInvitationList(response);
    }
  }

  async function cancelSubscriptionApi() {
    let selectedSubscription = profileDetails?.subscription[0];
    const url = CONFIG.BASE_URL + `subscription/cancel`;
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ subscriptionID: selectedSubscription._id }),
    });
    if (response.status === 200) {
      response = await response.json();
      toast(response.message);
      getAccountInfo();
    }
  }

  function cancelSubscription(flag) {
    if (flag) {
      cancelSubscriptionApi();
    }
    setIsDelete(false);
  }

  async function submitInvitation() {
    if (email) {
      if (optionSelected && optionSelected.length > 0) {
        let device_ids = optionSelected.map((item) => {
          return item.value;
        });
        const url = CONFIG.BASE_URL + CONFIG.SEND_INVITATION;
        let token = props.auth.token;
        let response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ email, device_ids }),
        });
        if (response.status === 200) {
          response = await response.json();
          toast("Invitation Sent Successfully!");
          // getAccountInfo();
        } else {
          response = await response.json();
          toast(response.error_message);
          getInvitationList();
        }
        setModal(false);
        setEmail("");
        setOptionSelected("");
      } else {
        toast("Please Select Device");
      }
    } else {
      toast("Please Enter Email");
    }
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleChange = (selected) => {
    setOptionSelected(selected);
  };

  const rowClick = (e, item, row) => {
    if (document.querySelector(".tabcontent.active")) {
      document.querySelector(".tabcontent.active").classList.remove("active");
    }
    let id = e.target.getAttribute("id");
    if (
      document.querySelector(`#collapsepan-${id}`).classList.contains("active")
    ) {
      document.querySelector(`#collapsepan-${id}`).classList.remove("active");
    } else {
      document.querySelector(`#collapsepan-${id}`).classList.add("active");
    }
    if (document.querySelector(`#tabcontent-${id}`)) {
      document.querySelector(`#tabcontent-${id}`).classList.add("active");
    }
  };

  async function deleteInvitation(e, item) {
    const url = CONFIG.BASE_URL + `${CONFIG.SEND_INVITATION}`;
    let token = props.auth.token;
    let obj = {
      email: item.email,
    };

    let response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    });
    if (response.status === 200) {
      response = await response.json();
      toast(response.message);
      getInvitationList();
    }
  }

  async function deleteDevice(e, device, item) {
    const url = CONFIG.BASE_URL + `${CONFIG.SEND_INVITATION}/${item._id}`;
    let token = props.auth.token;
    let obj = {};
    obj.device_ids = [];
    if (item && item.device_ids.length > 0) {
      obj.device_ids = item.device_ids.filter(
        (deviceid) => deviceid.device_id != device.device_id
      );
      obj.device_ids = obj.device_ids.map((deviceid) => deviceid.device_id);
    }
    obj.email = item.email;
    let response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    });
    if (response.status === 200) {
      response = await response.json();
      toast(response.message);
      getInvitationList();
    }
  }

  return (
    <Fragment>
      <div className="account-page">
        <div className="account-header">
          <div className="page-name">Account</div>
          {props.auth.role !== "subuser" ? (
            <span className="add" onClick={() => setModal(true)}>
              Invite User
            </span>
          ) : null}
        </div>
        <div className="account-wrapper">
          <img className="profile-img" src={profileIcon} alt="profile" />
          <p>
            {profileDetails?.firstname} {profileDetails?.lastname}
          </p>
          <p>{profileDetails?.email}</p>

          {invitationList && invitationList.length > 0 ? (
            <div className="list-wrapper">
              <div className="title">Invitation List</div>
              <div className="list-header">
                <div className="col-60">Email</div>
                <div className="col-20">Delete</div>
              </div>
              {invitationList.map((item, i) => {
                return (
                  <div className="list-row" key={item._id}>
                    <div className={`list ${i % 2 === 0 ? "even" : "odd"}`}>
                      <div
                        className="col-60 email"
                        id={item._id}
                        onClick={(e) => rowClick(e, item, "row")}
                      >
                        {item.email}
                      </div>
                      <div className="col-20">
                        <img
                          src={deleteIcon}
                          alt="delete"
                          onClick={(e) => deleteInvitation(e, item)}
                        />
                      </div>
                    </div>
                    <div
                      className="collapse-pan"
                      id={`collapsepan-${item._id}`}
                    >
                      <div className="tabcontent" id={`tabcontent-${item._id}`}>
                        {item.device_ids && item.device_ids.length > 0 ? (
                          <table>
                            <thead>
                              <tr>
                                <th className="small">Device ID</th>
                                <th className="small">Device Name</th>
                                <th className="small">Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.device_ids.map((device) => {
                                return (
                                  <tr key={device._id}>
                                    <td className="large">
                                      {device.device_id}
                                    </td>
                                    <td className="large">
                                      {device.device_name}
                                    </td>
                                    <td className="large">
                                      <img
                                        src={deleteIcon}
                                        alt="delete"
                                        onClick={(e) =>
                                          deleteDevice(e, device, item)
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}

          {profileDetails?.subscription &&
          profileDetails?.subscription.length > 0 ? (
            <div className="subscription-list-container">
              <div className="list-item head">
                <div className="header">Start Date</div>
                <div className="header">End Date</div>
                <div className="header">Remaining Days</div>
                <div className="header">Status</div>
              </div>
              {profileDetails &&
              profileDetails?.subscription &&
              profileDetails?.subscription.length > 0
                ? profileDetails?.subscription.map((item, i) => {
                    return i === 0 ? (
                      <div className="list-item" key={item._id}>
                        <div className="device-details">
                          {item.createdAt.split("T")[0]}
                        </div>
                        <div className="device-details">
                          {subscriptionEndDate}
                        </div>
                        <div className="device-details">{numberOfDays}</div>
                        <div className="device-details">
                          {numberOfDays > 0 ? "Active" : "Expired"}
                        </div>
                      </div>
                    ) : null;
                  })
                : null}
            </div>
          ) : null}
          {props.auth.role !== "subuser" ? (
            <Button
              className="btn btn-primary"
              onClick={(e) => setIsDelete(true)}
            >
              Cancel Subscription
            </Button>
          ) : null}
        </div>
      </div>
      <Popup isOpen={isDelete}>
        <PopupHeader toggle={(e) => setIsDelete(false)}>
          {" "}
          Cancel Subscription
        </PopupHeader>
        <PopupContent class="small">
          <h6>Do you cancel your subscription?</h6>
        </PopupContent>
        <PopupFooter>
          <Button color="primary" onClick={() => cancelSubscription(true)}>
            Okay
          </Button>
          <Button color="primary" onClick={() => cancelSubscription(false)}>
            Cancel
          </Button>
        </PopupFooter>
      </Popup>
      <Popup isOpen={modal} style={{ width: "40%" }}>
        <PopupHeader toggle={(e) => setModal(false)}>Invite User</PopupHeader>
        <PopupContent class="otp">
          <input
            type="text"
            className="text-input"
            placeholder="Enter Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <ReactSelect
            options={optionData}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Option,
            }}
            onChange={handleChange}
            allowSelectAll={true}
            value={optionSelected}
            placeholder="Select Device"
          />
        </PopupContent>
        <PopupFooter>
          <Button color="primary" onClick={submitInvitation}>
            Send Invitation
          </Button>
        </PopupFooter>
      </Popup>
      <ToastContainer />
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  device: state.device,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setDevices }, dispatch);
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SubscriptionPage
);
