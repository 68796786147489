const initialState = {devices:[]};

export default (state = initialState, action) => {
  const type = action && action.type;
  switch (type) {
    case 'SET_DEVICES':
      return { ...state,devices:action.data };
    default:
      return state;
  }
};
