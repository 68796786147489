import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./index.scss";
import logo from "../../assets/images/logo_new.jpg";
import { CONFIG } from "../../config/constant";
import Footer from "../../component/Footer/Footer";
import appleStore from "../../assets/images/apple-store.jpg";
import googleStore from "../../assets/images/google-playstore.jpg";

const LoginPage = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [commonError, setCommonError] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);
  const navigate = useNavigate();
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  function goToLogin() {
    navigate("/login");
  }

  function isValidEmail() {
    if (!email) {
      setCommonError("Email required");
      return false;
    }
    if (!email.match(regex)) {
      setCommonError("Invalid Email");
      return false;
    }
    return true;
  }

  function isValidOtp() {
    if (!otp) {
      setCommonError("Otp required");
      return false;
    }
    return true;
  }

  function isValidPassword() {
    if (!password) {
      setCommonError("Password required");
      return false;
    }
    return true;
  }

  async function changePassword() {
    if (!isValidEmail()) {
      return;
    }
    if (!isValidOtp()) {
      return;
    }
    if (!isValidPassword()) {
      return;
    }
    const data = { email: email.toLowerCase(), otp, password };
    const url = CONFIG.BASE_URL + "auth/reset-password";
    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    response = await response.json();
    if (response.error_message) {
      setCommonError(response.error_message);
    } else {
      toast(response.message);
      setTimeout(() => {
        goToLogin();
      }, 3000);
    }
  }

  async function forgetPassword() {
    if (!isValidEmail()) {
      return;
    }
    const data = { email: email.toLowerCase() };
    const url = CONFIG.BASE_URL + "auth/forgot-password";
    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    response = await response.json();
    if (response.error_message) {
      setCommonError(response.error_message);
    } else {
      toast(response.message);
      setShowOtpField(true);
    }
  }

  return (
    <div className="forget-page">
      <div className="header">
        <img className="app-logo" src={logo} alt="" />
        <div className="store-logo">
          <div className="coming-soon">Coming Soon</div>
          <div className="logos">
            <img className="store-image" src={appleStore} alt="appleStore" />
            <img className="store-image" src={googleStore} alt="googleStore" />
          </div>
        </div>
      </div>
      <div className="content">
        <div className="left-section"></div>
        <div className="right-section">
          {!showOtpField ? (
            <h2 className="title"> Forgot Password</h2>
          ) : (
            <h2 className="title"> Reset Password</h2>
          )}
          <form
            onSubmit={() => {
              return;
            }}
          >
            {!showOtpField ? (
              <Fragment>
                <input
                  type="text"
                  placeholder="Email *"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setCommonError("");
                  }}
                />
                <div className="common-error">{commonError}</div>
                <input
                  type="button"
                  onClick={forgetPassword}
                  value="Forgot Password"
                />
                <div className="forget" onClick={goToLogin}>
                  Back to login
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <input
                  type="text"
                  placeholder="Email *"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setCommonError("");
                  }}
                />
                <input
                  type="text"
                  placeholder="Password *"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setCommonError("");
                  }}
                />
                <input
                  type="text"
                  placeholder="Otp *"
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                    setCommonError("");
                  }}
                />
                <div className="common-error">{commonError}</div>
                <input
                  type="button"
                  onClick={changePassword}
                  value="Change Password"
                />
                <div className="forget" onClick={goToLogin}>
                  Back to login
                </div>
              </Fragment>
            )}
          </form>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default LoginPage;
