import Cookies from "universal-cookie";
const cookies = new Cookies();
let isLogin = cookies.get("isLogin") || false;
isLogin = isLogin === "true" || isLogin === true;
const initialState = {
  token: cookies.get("token") || "",
  isLogin,
  email: cookies.get("email") || "",
  role: cookies.get("role") || "",
  userid: cookies.get("userid") || "",
  device_ids: cookies.get("device_ids") || [],
  owner_id: cookies.get("owner_id") || "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  const type = action && action.type;
  switch (type) {
    case "SET_TOKEN":
      return { ...state, ...action.token };
    case "SET_AUTH_INFO":
      return { ...state, ...action.data };
    case "SET_LOGIN_STATUS":
      return { ...state, isLogin: action.isLogin };
    default:
      return state;
  }
};
