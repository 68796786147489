import React, { Fragment } from "react";

import './Popup.scss'

export const PopupHeader = (props) => {
    function close() {
        if (props.toggle) {
            props.toggle(false);
        }
    }
    return (
        <div className="modal-header">
            {props.children}
            <span className="close-btn" onClick={() => close()}>X</span>
        </div>
    )
};

export const PopupContent = (props) => {
    return (
        <div className={`modal-content ${props.class}`}>
            {props.children}
        </div>
    )
};

export const PopupFooter = (props) => {
    return (
        <div className="modal-footer">
            {props.children}
        </div>
    )
};

export const Popup = (props) => {
    return (
        <Fragment>
            {props.isOpen ? (<div className="modal-wrapper">
                <div className="modal-container" style={props.style}>
                    {props.children}
                </div>
            </div>) : null}
        </Fragment>
    )
};