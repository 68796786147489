import React, { useEffect, useState } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

import "./Header.scss";
import { setAuthInfo } from "../../module/auth/auth.action";
import { setFolder } from "../../module/content/content.action";
import logo from "../../assets/images/logo_new.jpg";

const Header = (props) => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [showLogout, setShowLogout] = useState(false);

  useEffect(() => {}, []);

  const showMenu = () => {
    setShowLogout(!showLogout);
  };

  const logout = () => {
    cookies.remove("email");
    cookies.remove("token");
    cookies.remove("role");
    cookies.remove("userid");
    cookies.remove("isLogin");
    cookies.remove("device_ids");
    props.setAuthInfo({
      email: "",
      token: "",
      role: "",
      userid: "",
      isLogin: false,
    });
    props.setFolder([]);
    setShowLogout(!showLogout);
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  };

  return (
    <div className="header-container">
      <img src={logo} alt="" onClick={() => navigate("folder")} />
      <div className="profile-menu-wrapper">
        <div className="profile-img-wrapper" onClick={() => showMenu()}>
          {/* <img src={logo} alt="" /> */}
          <div>{cookies.get("email")?.split("@")[0]}</div>
        </div>
        {showLogout ? (
          <ul className="profile-submenu" onClick={() => logout()}>
            <li>Logout</li>
          </ul>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setAuthInfo, setFolder }, dispatch);
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header);
