const initialState = null;

export default (state = initialState, action) => {
  const type = action && action.type;
  switch (type) {
    case 'SET_PROFILE':
      return { ...action.data };
    default:
      return state;
  }
};
