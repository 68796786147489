import React, { useState, useEffect, Fragment } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "reactstrap";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import "./index.scss";
import { CONFIG } from "../../config/constant";
import {
  Popup,
  PopupHeader,
  PopupFooter,
  PopupContent,
} from "../../component/Popup/Popup";
import { setDevices } from "../../module/device/device.action";
import Footer from "../../component/Footer/Footer";

const Devices = (props) => {
  const [modal, setModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState();
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      props.device.devices &&
      !props.device.devices.length &&
      props.auth.role !== "subuser"
    ) {
      getDevices();
    }
    if (props.auth.role == "subuser") {
      props.setDevices(props.auth.device_ids);
    }
  }, []);

  async function getDevices() {
    const url = CONFIG.BASE_URL + CONFIG.DEVICE_LIST;
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      response = await response.json();
      props.setDevices(response.data);
      setIsLoading(false);
    }
  }

  async function submitDeviceOtp() {
    const url = CONFIG.BASE_URL + CONFIG.VALIDATE_OTP;
    let token = props.auth.token;
    let data = { otp };
    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      toast("Device Linked Successfully.");
      getDevices();
    } else {
      response = await response.json();
      toast(response.error_message);
    }
    setOtp("");
  }

  function submitOtp() {
    submitDeviceOtp();
    setModal(false);
  }

  function deleteDevice(flag) {
    if (flag) {
      deleteLinking();
    }
    setIsDelete(false);
  }

  async function deleteLinking() {
    const url = CONFIG.BASE_URL + CONFIG.DEVICE_LIST;
    let token = props.auth.token;
    let data = { device_id: selectedDevice };
    let response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    });

    if (response.status === 200) {
      response = await response.json();
      toast(response.message);
      getDevices();
      setSelectedDevice("");
    } else {
      response = await response.json();
      toast(response.error_message);
    }
  }

  return (
    <Fragment>
      <div className="device-page">
        <div className="device-header">
          <div className="page-name">Device List</div>
          {props.auth.role !== "subuser" ? (
            <span className="add" onClick={() => setModal(true)}>
              Link Device
            </span>
          ) : null}
        </div>
        {props.device.devices && props.device.devices.length > 0 ? (
          <div className="device-list-container">
            <div className="list-item head">
              <div className="header">Device ID</div>
              <div className="header">Device Name</div>
              {props.auth.role !== "subuser" ? (
                <div className="delete-header">Unlink</div>
              ) : null}
            </div>
            {props.device.devices && props.device.devices.length > 0
              ? props.device.devices.map((item) => {
                  return (
                    <div className="list-item" key={item._id}>
                      <div className="device-details">{item.device_id}</div>
                      <div className="device-details">{item.device_name}</div>
                      {props.auth.role !== "subuser" ? (
                        <div
                          className="delete"
                          onClick={() => {
                            setSelectedDevice(item.device_id);
                            setIsDelete(true);
                          }}
                        ></div>
                      ) : null}
                    </div>
                  );
                })
              : null}
          </div>
        ) : null}
        {!isLoading && props.device.devices && !props.device.devices.length ? (
          <div className="no-device">No device linked with your account</div>
        ) : null}
      </div>
      <Popup isOpen={modal}>
        <PopupHeader toggle={(e) => setModal(false)}>Link Device</PopupHeader>
        <PopupContent class="otp">
          <input
            type="text"
            className="text-input"
            placeholder="Enter otp"
            onChange={(e) => setOtp(e.target.value)}
            value={otp}
          />
        </PopupContent>
        <PopupFooter>
          <Button color="primary" onClick={() => submitOtp()}>
            Done
          </Button>
        </PopupFooter>
      </Popup>
      <Popup isOpen={isDelete}>
        <PopupHeader toggle={(e) => setIsDelete(false)}>
          {" "}
          Unlink Device
        </PopupHeader>
        <PopupContent class="small">
          <h6>Do you want to unlink this device?</h6>
        </PopupContent>
        <PopupFooter>
          <Button color="primary" onClick={() => deleteDevice(true)}>
            Okay
          </Button>
          <Button color="primary" onClick={() => deleteDevice(false)}>
            Cancel
          </Button>
        </PopupFooter>
      </Popup>
      <ToastContainer />
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  device: state.device,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setDevices }, dispatch);
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Devices);
