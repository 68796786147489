export function setFolder(data) {
  return {
    type: 'SET_FOLDER',
    data
  };
}

export function setContent(data) {
  return {
    type: 'SET_CONTENT',
    data
  };
}