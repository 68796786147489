import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import "./index.scss";
import logo from "../../assets/images/logo_new.jpg";
import { useCookies } from "react-cookie";
import { setAuthInfo } from "../../module/auth/auth.action";
import { CONFIG } from "../../config/constant";
import Footer from "../../component/Footer/Footer";
import appleStore from "../../assets/images/apple-store.jpg";
import googleStore from "../../assets/images/google-playstore.jpg";

const SignupPage = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [commonError, setCommonError] = useState("");
  const [, setCookie] = useCookies(["auth"]);
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const navigate = useNavigate();

  /**
   * It navigates to the login page.
   */
  function goToLogin() {
    navigate("/login");
  }

  /**
   * It navigates to the forgot password page.
   */
  // function goToForgetPassword() {
  //   navigate("/forget");
  // }

  /**
   * If the email is not empty and matches the regex, then return true. Otherwise, return false
   * @returns A boolean value.
   */
  function isValidEmail() {
    if (!email) {
      setCommonError("Email required");
      return false;
    }
    if (!email.match(regex)) {
      setCommonError("Invalid Email");
      return false;
    }
    return true;
  }

  /**
   * If the password is not defined, set the password error to "Password required" and return false.
   * Otherwise, return true
   * @returns a boolean value.
   */
  function isValidPassword() {
    if (!password) {
      setCommonError("Password required");
      return false;
    }
    return true;
  }

  /**
   * It checks if the confirm password is empty. If it is, it sets the error message and returns false.
   * Otherwise, it returns true
   * @returns a boolean value.
   */
  function isValidConfirmPassword() {
    if (!confirmPassword) {
      setCommonError("Confirm password should not be empty");
      return false;
    }
    return true;
  }
  /**
   * It takes the email and password from the form, sends it to the server, and if the server returns a
   * token, it sets the token in the cookie and navigates to the folder page
   * @returns the response from the server.
   */

  async function registerAccount() {
    if (!isValidEmail()) {
      return;
    }
    if (!isValidPassword()) {
      return;
    }

    if (!isValidConfirmPassword()) {
      return;
    }

    if (email && password) {
      const data = { email: email.toLowerCase(), password };
      const url = CONFIG.BASE_URL + "auth/register";
      let response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      response = await response.json();
      if (response.error_message) {
        setCommonError(response.error_message);
      } else {
        props.setAuthInfo({
          email: data.email,
          token: data.token,
          role: data.role,
          userid: data._id,
          isLogin: true,
        });
        setCookie("email", response.email, { path: "/" });
        setCookie("token", response.token, { path: "/" });
        setCookie("role", response.role, { path: "/" });
        setCookie("userid", response._id, { path: "/" });
        setCookie("isLogin", true, { path: "/" });
        navigate("/folder");
      }
    }
  }

  return (
    <div className="signup-page">
      <div className="header">
        <img className="app-logo" src={logo} alt="" />
        <div className="store-logo">
          <div className="coming-soon">Coming Soon</div>
          <div className="logos">
            <img className="store-image" src={appleStore} alt="appleStore" />
            <img className="store-image" src={googleStore} alt="googleStore" />
          </div>
        </div>
      </div>
      <div className="content">
        <div className="left-section"></div>
        <div className="right-section">
          <h2 className="title"> join now</h2>
          <h4 className="sub-title">FREE to Sign up</h4>
          <h4 className="sub-title">No Credit Card required</h4>
          <form
            onSubmit={() => {
              return;
            }}
          >
            <input
              type="text"
              placeholder="Email *"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setCommonError("");
              }}
            />
            <input
              type="password"
              placeholder="Password *"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setCommonError("");
              }}
            />
            <input
              type="password"
              placeholder="Confirm Password *"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setCommonError("");
              }}
            />
            <div className="common-error">{commonError}</div>
            <input
              type="button"
              onClick={registerAccount}
              value="Create Account"
            />
            {/* <div className="forget" onClick={goToLogin}>
              Back to login
            </div> */}
            <div className="login-link">
              Already have an Account?{" "}
              <span onClick={goToLogin}>Login here.</span>
            </div>{" "}
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setAuthInfo,
    },
    dispatch
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SignupPage
);
