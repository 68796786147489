const initialState = {folders:[]};

export default (state = initialState, action) => {
  const type = action && action.type;
  switch (type) {
    case 'SET_FOLDER':
      return { ...state,folders:action.data };
    case 'SET_CONTENT':  
      return { ...state,contents:action.data };
    default:
      return state;
  }
};
