import React, { useState, Fragment, useEffect } from "react";
import { Button, Label, FormGroup } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import DateTimePicker from "react-datetime-picker";
import "./index.scss";
import {
  Popup,
  PopupHeader,
  PopupFooter,
  PopupContent,
} from "../../component/Popup/Popup";
import { FileUpload, getFileUrl } from "../../component/FileUpload";
import { CONFIG } from "../../config/constant";
import { setFolder, setContent } from "../../module/content/content.action";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { default as ReactSelect, components } from "react-select";
import axios from "axios";

import addIcon from "../../assets/images/add.svg";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/delete.svg";
import repeatIcon from "../../assets/images/event_repeat.svg";
import repeatIconActive from "../../assets/images/event_repeat_active.svg";
import scheduleIcon from "../../assets/images/schedule.svg";
import videoIcon from "../../assets/images/video.png";
import audioIcon from "../../assets/images/audio.png";
import imageIcon from "../../assets/images/image.png";
import unknownIcon from "../../assets/images/unknown.png";
import musicGif from "../../assets/images/music.gif";
import Footer from "../../component/Footer/Footer";

const ContentPage = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [progress, setProgress] = useState(0);
  const [createModal, setCreateModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [repeatModal, setRepeatModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState();
  const [contentData, setContentData] = useState({});
  const [scheduleData, setScheduleData] = useState({
    title: "",
    description: "",
    status: "",
    thumbnail: "",
  });
  const [startDate, onChangeStart] = useState(new Date());
  const [endDate, onChangeEnd] = useState(new Date());
  const [isRepeated, setIsRepeated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [optionSelected, setOptionSelected] = useState("");
  const [optionData, setOptionData] = useState([]);

  useEffect(() => {
    if (!props.content.contents || !props.content.contents.length) {
      getContent();
    }
    formatDeviceData();
  }, []);

  const toggle = async (flag) => {
    if (flag) {
      createContent();
    }
    setCreateModal(!createModal);
  };

  const toggleEdit = async (flag) => {
    if (flag) {
      updateContent();
    }
    setCreateModal(!createModal);
  };

  const openModel = async () => {
    setContentData({
      content_url: "",
      title: "",
      description: "",
      thumbnail: "",
      type: "",
      status: "",
      parent_content_directory: "",
    });
    setDeleteModal(false);
    setIsEdit(false);
    setCreateModal(true);
  };

  function formatDeviceData() {
    const deviceList = [];
    if (props.device.devices.length > 0) {
      for (let i = 0; i < props.device.devices.length; i++) {
        let obj = {
          value: props.device.devices[i].device_id,
          label: props.device.devices[i].device_name,
        };
        deviceList.push(obj);
      }
      setOptionData(deviceList);
    }
  }

  async function getContent() {
    setIsLoading(true);
    const url = `${CONFIG.BASE_URL}content?parent_content_directory=${params.id}`;
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      response = await response.json();
      setIsLoading(false);
      props.setContent(response);
    } else {
      setIsLoading(false);
      props.setContent([]);
    }
  }

  async function createContent() {
    const url = CONFIG.BASE_URL + "content";
    let token = props.auth.token;
    let data = {
      title: contentData.title,
      // description: contentData.description,
      description: "",
      // thumbnail: contentData.thumbnail,
      content_url: contentData.content_url,
      // status: contentData.status,
      status: "active",
      parent_content_directory: params.id,
      type: contentData.type,
    };
    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      toast("Content Created Successfully.");
      setProgress(0);
      setScheduleData({
        title: "",
        description: "",
        status: "",
        thumbnail: "",
      });
      setContentData({});
      await getContent();
    } else {
      response = await response.json();
      toast(response.error_message);
    }
  }

  async function updateContent() {
    const url = CONFIG.BASE_URL + `content/${selectedContent._id}`;
    let token = props.auth.token;
    let data = {
      title: contentData.title || selectedContent.title,
      description: contentData.description || selectedContent.description,
      content_url: contentData.content_url || selectedContent.content_url,
      thumbnail: contentData.thumbnail || selectedContent.thumbnail,
      status: "active",
      parent_content_directory: params.id,
      type: contentData.type || selectedContent.type,
      isInfiniteLoop: selectedContent.isInfiniteLoop || false,
    };
    let response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      toast("Content Updated Successfully.");
      await getContent();
    } else {
      response = await response.json();
      toast(response.error_message);
    }
  }

  async function deleteContent() {
    const url = CONFIG.BASE_URL + `content/${selectedContent._id}`;
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      toast("Content Deleted Successfully.");
      await getContent();
    }
  }

  async function handleFileChange(file, key) {
    let fileUrl = await getFileUrl(file.name, props.auth.token);
    let fileReader = new FileReader();
    fileReader.onload = function (e) {
      let blob = new Blob([new Uint8Array(e.target.result)], {
        type: file.type,
      });
      onLoadsuccess(blob, file.type, fileUrl, key);
    };
    fileReader.readAsArrayBuffer(file);
  }

  async function onLoadsuccess(blob, type, fileUrl, key) {
    // console.log(blob, type, fileUrl, key);
    // let response = await fetch(fileUrl, {
    //   method: "PUT",
    //   mode: "cors",
    //   cache: "no-cache",
    //   headers: {
    //     "Content-Type": type,
    //   },
    //   body: blob,
    //   onprogress: function (e) {
    //     console.log(e);
    //   },
    // });
    // if (response.status === 200) {
    //   let finalUrl = fileUrl?.split("?")[0];
    //   if (scheduleModal || repeatModal) {
    //     setScheduleData({ ...scheduleData, thumbnail: finalUrl });
    //   } else {
    //     let obj = {};
    //     obj[key] = finalUrl;
    //     obj["type"] = getContentType(finalUrl);
    //     setContentData({ ...contentData, ...obj });
    //   }
    // }

    axios({
      url: fileUrl,
      method: "PUT",
      headers: {
        "Content-Type": type,
      },
      data: blob,
      onUploadProgress: (progressEvent) => {
        let percentage = (progressEvent.loaded / blob.size) * 100;
        setProgress(percentage);
      },
    }).then(function (resp) {
      if (resp.status === 200) {
        let finalUrl = fileUrl?.split("?")[0];
        if (scheduleModal || repeatModal) {
          setScheduleData({ ...scheduleData, thumbnail: finalUrl });
        } else {
          let obj = {};
          obj[key] = finalUrl;
          obj["type"] = getContentType(finalUrl);
          setContentData({ ...contentData, ...obj });
        }
      }
    });
  }

  function openContent(e, item) {
    let name = e.target.getAttribute("name");
    switch (name) {
      case "edit":
        setDeleteModal(false);
        setIsEdit(true);
        setSelectedContent(item);
        setContentData({
          content_url: item.content_url,
          title: item.title,
          // description: item.description,
          // thumbnail: item.thumbnail,
          // status: item.status,
          type: item.type,
          parent_content_directory: item.parent_content_directory,
        });
        setCreateModal(true);
        break;
      case "delete":
        setSelectedContent(item);
        setIsEdit(false);
        setDeleteModal(true);
        break;
      case "schedule":
        setScheduleModal(true);
        setScheduleData({
          content_id: item._id,
        });
        break;
      case "repeat":
        setRepeatModal(true);
        setSelectedContent(item);
        setScheduleData({
          content_id: item._id,
        });
        break;
      default:
        setSelectedContent(item);
        setPreviewModal(true);
        break;
    }
  }

  const deleteDirectory = (flag) => {
    if (flag) {
      deleteContent();
    }
    setDeleteModal(false);
  };

  const getContentImage = (url) => {
    if (!url) {
      return unknownIcon;
    }
    let type = url.split(".");
    type = type[type.length - 1];
    type = type && type.toLowerCase();
    switch (type) {
      case "mp4":
      case "wav":
      case "wma":
      case "mpeg-4":
      case "mov":
      case "avi":
        return videoIcon;
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return imageIcon;
      case "mp3":
      case "aac":
        return audioIcon;
    }
  };

  const getContentType = (url) => {
    if (!url) {
      return "unknown";
    }
    let type = url.split(".");
    type = type[type.length - 1];
    type = type && type.toLowerCase();
    switch (type) {
      case "mp4":
      case "wav":
      case "wma":
      case "mpeg-4":
      case "mov":
      case "avi":
        return "video";
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return "image";
      case "mp3":
      case "aac":
        return "audio";
    }
  };

  const toggleSchedulePopup = (flag) => {
    if (flag) {
      createSchedule();
    }
    setScheduleModal(false);
  };

  const createSchedule = async () => {
    let arr = [];
    if (optionSelected && optionSelected.length > 0) {
      for (let i = 0; i < optionSelected.length; i++) {
        arr.push(optionSelected[i]?.value);
      }
    }
    const obj = {
      ...scheduleData,
      device_ids: arr,
      start_date: new Date(startDate).toISOString(),
      end_date: new Date(endDate).toISOString(),
    };

    const url = CONFIG.BASE_URL + `scheduled_content`;
    let token = props.auth.token;
    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    });
    if (response.status === 200) {
      toast("Schedule Created Successfully.");
    }
  };

  const toggleRepeatPopup = (flag) => {
    if (flag) {
      updateContent();
    }
    setRepeatModal(false);
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleChange = (selected) => {
    setOptionSelected(selected);
  };

  const mimeType = (url) => {
    let type = url.split(".");
    type = type[type.length - 1];
    type = type.toLowerCase();
    switch (type) {
      case "mp4":
      case "wav":
      case "wma":
      case "mpeg-4":
        return "video/mp4";
      case "mov":
        return "video/mov";
      case "avi":
        return "video/avi";
    }
  };

  return (
    <Fragment>
      <div className="content-page">
        <div className="page-header">
          <div className="page-name">
            <span
              className="text-enable"
              onClick={(e) => {
                navigate("/folder");
              }}
            >
              Folders
            </span>{" "}
            / <span className="text-disable">{params.title}</span>
          </div>
          {props.auth.role !== "subuser" ? (
            <span className="add" onClick={() => openModel("content")}>
              <img src={addIcon} alt="add content" />
              Add Content
            </span>
          ) : null}
        </div>
        {props.content.contents && props.content.contents.length ? (
          <div className="file-wrapper">
            {props.content.contents.map((item) => {
              return (
                <div
                  key={item._id}
                  style={{
                    backgroundImage: `url(${getContentImage(
                      item.content_url
                    )})`,
                  }}
                  className="file"
                  onClick={(e) => openContent(e, item)}
                >
                  {props.auth.role !== "subuser" ? (
                    <div className="icon-wrapper">
                      <img
                        src={
                          item.isInfiniteLoop ? repeatIconActive : repeatIcon
                        }
                        alt="repeat icon"
                        name="repeat"
                      />
                      <img
                        src={scheduleIcon}
                        alt="schedule icon"
                        name="schedule"
                      />
                      <img src={editIcon} alt="edit icon" name="edit" />
                      <img src={deleteIcon} alt="delete icon" name="delete" />
                    </div>
                  ) : null}
                  <div className="title">
                    <span>{item.title}</span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : !isLoading ? (
          <div className="no-folder">
            <div>Woops, no content exist !</div>
          </div>
        ) : (
          <div className="no-folder">
            <div>Loading...</div>
          </div>
        )}
      </div>
      <Popup isOpen={createModal}>
        <PopupHeader toggle={toggle}>
          {isEdit ? "Edit Content" : "Add Content"}
        </PopupHeader>
        <PopupContent>
          <input
            type="text"
            className="text-input"
            placeholder="Enter Content Name"
            onChange={(e) =>
              setContentData({ ...contentData, title: e.target.value })
            }
            value={contentData.title}
          />
          <span style={{ textAlign: "left" }}>
            {isEdit ? "Update Content:" : "Upload Content:"}
            {progress ? ` ${parseInt(progress, 10)}% uploaded` : ""}
          </span>
          <FileUpload
            handleFileChange={handleFileChange}
            custom="content_url"
          />
          <span className="file-format">
            File format:( jpg, jpeg, png, mp3, wav, aac, wma, mp4, mpeg-4, mov,
            avi )
          </span>
        </PopupContent>
        <PopupFooter>
          {isEdit ? (
            <Button color="primary" onClick={() => toggleEdit(true)}>
              Update
            </Button>
          ) : progress == 100 ? (
            <Button color="primary" onClick={() => toggle(true)}>
              Add
            </Button>
          ) : (
            <Button color="primary disabled">Add</Button>
          )}
          <Button color="primary" onClick={() => toggle(false)}>
            Cancel
          </Button>
        </PopupFooter>
      </Popup>
      <Popup isOpen={deleteModal}>
        <PopupHeader toggle={(e) => setDeleteModal(false)}>
          {" "}
          Delete Content
        </PopupHeader>
        <PopupContent class="small">
          <h6>Do you want to delete content?</h6>
        </PopupContent>
        <PopupFooter>
          <Button color="primary" onClick={() => deleteDirectory(true)}>
            Okay
          </Button>
          <Button color="primary" onClick={() => deleteDirectory(false)}>
            Cancel
          </Button>
        </PopupFooter>
      </Popup>
      <Popup isOpen={scheduleModal}>
        <PopupHeader toggle={toggleSchedulePopup}>Schedule Content</PopupHeader>
        <PopupContent>
          <input
            type="text"
            className="text-input"
            placeholder="Enter Title"
            onChange={(e) =>
              setScheduleData({ ...scheduleData, title: e.target.value })
            }
            value={scheduleData.title}
          />
          <input
            type="text"
            className="text-input"
            placeholder="Enter Description"
            onChange={(e) =>
              setScheduleData({ ...scheduleData, description: e.target.value })
            }
            value={scheduleData.description}
          />
          <select
            value={scheduleData.status}
            onChange={(e) =>
              setScheduleData({ ...scheduleData, status: e.target.value })
            }
          >
            <option key={"blank"} value={""}>
              Select Mode
            </option>
            <option key={"active"} value={"active"}>
              Active
            </option>
            <option key={"inactive"} value={"inactive"}>
              Inactive
            </option>
          </select>
          {/* <span style={{ textAlign: "left" }}>Update Thumbnail</span>
          <FileUpload handleFileChange={handleFileChange} custom="thumbnail" /> */}
          <FormGroup>
            <Label
              for="scheduleDate"
              style={{ width: "100%", textAlign: "left" }}
            >
              {" "}
              Schedule Start
            </Label>
            <DateTimePicker
              onChange={onChangeStart}
              value={startDate}
              minDate={new Date()}
              required={true}
              format="dd-MM-y h:mm:ss a"
              style={{ marginLeft: "-140px" }}
            />
          </FormGroup>
          <FormGroup>
            <Label
              for="scheduleDate"
              style={{ width: "100%", textAlign: "left" }}
            >
              {" "}
              Schedule End
            </Label>
            <DateTimePicker
              onChange={onChangeEnd}
              value={endDate}
              minDate={new Date()}
              required={true}
              format="dd-MM-y h:mm:ss a"
              style={{ marginLeft: "-140px" }}
            />
          </FormGroup>
          <FormGroup>
            <ReactSelect
              options={optionData}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
              onChange={handleChange}
              allowSelectAll={true}
              value={optionSelected}
              placeholder="Select Device"
            />
          </FormGroup>
        </PopupContent>
        <PopupFooter>
          <Button color="primary" onClick={() => toggleSchedulePopup(true)}>
            Okay
          </Button>
          <Button color="primary" onClick={() => toggleSchedulePopup(false)}>
            Cancel
          </Button>
        </PopupFooter>
      </Popup>
      <Popup isOpen={repeatModal}>
        <PopupHeader toggle={toggleRepeatPopup}>Loop Content</PopupHeader>
        <PopupContent>
          <select
            value={selectedContent?.isInfiniteLoop}
            onChange={(e) =>
              setSelectedContent({
                ...selectedContent,
                isInfiniteLoop: e.target.value,
              })
            }
          >
            <option key={"blank"} value={false}>
              Select Mode
            </option>
            <option key={"infinite"} value={true}>
              Infinite
            </option>
            <option key={"none"} value={false}>
              None
            </option>
          </select>
        </PopupContent>
        <PopupFooter>
          <Button color="primary" onClick={() => toggleRepeatPopup(true)}>
            Okay
          </Button>
          <Button color="primary" onClick={() => toggleRepeatPopup(false)}>
            Cancel
          </Button>
        </PopupFooter>
      </Popup>
      <Popup isOpen={previewModal}>
        <PopupHeader
          toggle={() => {
            setPreviewModal(false);
            setAudioPlaying(false);
          }}
        >
          Preview Content
        </PopupHeader>
        <PopupContent class={"center"}>
          {selectedContent &&
          (selectedContent.type === "image" ||
            getContentType(selectedContent.content_url) === "image") ? (
            <img
              className="preview-content"
              src={selectedContent.content_url}
              alt="content url"
            />
          ) : null}
          {selectedContent &&
          (selectedContent.type === "audio" ||
            getContentType(selectedContent.content_url) === "audio") ? (
            <Fragment>
              <audio
                src={selectedContent.content_url}
                controls
                autoPlay
                preload="auto"
                onPlay={() => {
                  setAudioPlaying(true);
                }}
                onEnded={() => {
                  setAudioPlaying(false);
                }}
              ></audio>
              <img
                className="preview-content"
                src={audioPlaying ? musicGif : audioIcon}
                alt="content url"
              />
            </Fragment>
          ) : null}

          {selectedContent &&
          (selectedContent.type === "video" ||
            getContentType(selectedContent.content_url) === "video") ? (
            <video
              className="preview-content"
              src={selectedContent.content_url}
              type={mimeType(selectedContent.content_url)}
              controls
              autoPlay
            ></video>
          ) : null}
        </PopupContent>
      </Popup>
      <ToastContainer />
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  content: state.content,
  device: state.device,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setFolder, setContent }, dispatch);
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ContentPage
);
