import React, { Fragment } from "react";

import './PackCard.scss'

const PackCard = (props) => {
    return (
        <Fragment>
        {props.data.status==='active'?(
        <div className="card" key={props.data._id}>
            <h4>{props.data.name}</h4>
            <div className="details-row"><span className="price">{props.data.price} {props.data.currency}</span></div>
            <div className="details-row"><span className="time">{props.data.plan_expiry_in_days} Month</span></div>
            <div className="details-row"><span className="devices">{props.data.max_connected_device} Devices</span></div>
            <button className="subscribe-btn" onClick={(e)=>props.clickHandler(e,props.data)}>Subscribe</button>
        </div>):null}
        </Fragment>
    )

};

export default PackCard;